import React from 'react';
import './ToolTip.css';

const ToolTip = ({ message }) => {
    return (
        <div className="tooltip">
            {message}
            <span className="tooltiptext">{message}</span>
        </div>
    );
};

export default ToolTip;
