// TailoredSkills.js
import './TailoredSkills.css';
import AuthContext from './AuthContext';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useContext, useRef } from 'react';

const TailoredSkills = () => {
    const navigate = useNavigate();
    const [text, setText] = useState('');
    const [skillsData, setSkillsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { token, isAuthenticated, logoutUser, refreshAccessToken } = useContext(AuthContext);
    const [quotaError, setQuotaError] = useState(false);
    const [fileError, setFileError] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL
    const mainContentRef = useRef(null); // Ref for the main content
    
    const handleLogout = () => {
        logoutUser();
        navigate('/login');
    };

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        }
    }, [isAuthenticated, navigate]);
    const handleTextSubmit = (event) => {
        setIsLoading(true);
        event.preventDefault();
        const data = { text };
    
        const submitData = (retry = false) => {
            fetch(`${apiUrl}/api/generate-tailored-skills/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then(response => {
                if (response.status === 401 && !retry) {
                    // Try to refresh token and retry submitting data once
                    return refreshAccessToken().then(() => submitData(true));
                }
                if (response.status === 403) {
                    throw new Error("Usage quota exceeded");
                }
                if (response.status === 400) {
                    setFileError(true);
                    throw new Error("No files were found, please upload work materials or a resume in My Profile.")
                }
                return response.json();
            })
            .then(data => {
                // Parse the Content field for each skill
                setSkillsData(data);
                console.log(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error:', error);
                if (error.message === "Usage quota exceeded") {
                    setQuotaError(true);
                }
                setIsLoading(false);
            });
        };
    
        submitData();
    };
    return (
        <div className="skills-page">
            <Header 
                    title="Tailored Skill Developer" 
                    onLogout={handleLogout}
            />
            <main className="skills-page-main" ref={mainContentRef}>
                <div className="f-modal-base-large">
                    <div className="f-modal-title-wrapper">
                        <div className="f-sub-heading-regular">Identify Gaps in your Skills/Knowledge</div>
                    </div>
                    <div className="f-modal-line"></div>
                        <div className="w-form">
                            <form id="email-form" name="email-form" data-name="Email Form" method="get" data-wf-page-id="65d123afabbdd97a28b30374" data-wf-element-id="20931e75-87b3-acde-2f50-563023bdd402">
                            <div className="w-layout-grid f-account-input-grid">
                                <div className="f-field-wrapper-2">
                                    <div data-hover="false" data-delay="0" className="f-dropdown w-dropdown">
                                    </div>
                                </div>
                                <div className="f-field-wrapper-2">
                                    <div className="f-field-label">Job Description</div>
                                    <textarea 
                                        value={text}
                                        onChange={(e) => setText(e.target.value)}
                                        placeholder="Copy and paste job description, details and requirements here for the AI to use when updating your resume. " maxLength="5000" id="Modal-Field-Large" name="Modal-Field-Large" data-name="Modal Field Large" className="f-text-area-2 w-input"></textarea>
                                </div>
                            </div>
                            </form>
                        </div>
                    <div className="f-modal-line"></div>
                    <button onClick={handleTextSubmit} className="urlSubmit" type="submit" disabled={isLoading}>
                        {isLoading ? 'Analyzing...' : 'Analyze Job Description'}
                    </button>
                    {isLoading}
                </div>
                {
                    quotaError && (
                        <div className="quota-error-message">
                            You have exceeded your usage quota for this week.
                        </div>
                    )
                }
                {
                    fileError && (
                        <div className="quota-error-message">
                            You have not uploaded a resume or work materials, please upload files at My Profile.
                        </div>
                    )
                }
                <div className="projects-container">
                    <div className="projects-grid">
                        {Object.entries(skillsData).map(([skill, details], index) => (
                            <div key={index} className="project-document">
                                <h2 className="project-title">{skill}</h2>
                                <div className="project-details">
                                    <p><strong>Project:</strong> {details.Project}</p>
                                    <p><strong>Resources:</strong> {details.Resources.join(', ')}</p>
                                </div>
                                <div className="project-content">
                                    <h4>Steps:</h4>
                                    <ol>
                                        {Object.entries(details.Content.Steps).map(([stepNumber, stepDetail], idx) => (
                                            <li key={idx}>{stepDetail}</li>
                                        ))}
                                    </ol>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </main>
        </div>
        );
    };

    export default TailoredSkills;
