import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './RegisterForm.css'; // Make sure to link the CSS file
import logo from './logos/logo.png';

const RegisterForm = () => {
    const navigate = useNavigate();
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [profileDescription, setProfileDescription] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(''); 
    const [promoCode, setPromoCode] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');

        // Basic Validation
        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/api/register/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    full_name: fullName,
                    email: email,
                    phone_number: phoneNumber,
                    profile_description: profileDescription,
                    password: password,
                    promo_code: promoCode,
                }),
            });

            const data = await response.json();

            if (response.ok) {
                navigate('/login');
            } else {
                // Display error message from response
                setError(data.error || 'Registration failed. Please try again.');
            }
        } catch (error) {
            setError('An error occurred. Please try again.');
        }
    };

    return (
        <div>
            <img src={logo} alt="Bridge Path - Job Hunting AI" className="register-form-logo" />
            <div className="register-container">
                <form onSubmit={handleSubmit} className="register-form">
                    <h1 className="form-title">Bridge Path - Job Hunting AI</h1> 
                    <h2 className="register-title">Register</h2>
                    <input
                        type="text"
                        placeholder="Full Name"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        required
                        className="register-input"
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="register-input"
                    />
                    <input
                        type="text"
                        placeholder="Phone Number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        className="register-input"
                    />
                    <textarea
                        placeholder="Current or Desired Job Title"
                        value={profileDescription}
                        onChange={(e) => setProfileDescription(e.target.value)}
                        className="register-input"
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="register-input"
                    />
                    <input
                        type="password"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        className="register-input"
                    />
                    <input
                        type="text"
                        placeholder="Promo Code"
                        value={promoCode}
                        onChange={(e) => setPromoCode(e.target.value)}
                        className="register-input"
                    />
                    <button type="submit" className="register-button">Register</button>
                    {error && <p className="register-error">{error}</p>}
                </form>
            </div>
        </div>
    );
};

export default RegisterForm;