import { useEffect } from 'react';

const useAdjustHeight = (headerRef, contentRef, deps) => {
    useEffect(() => {
        const adjustHeight = () => {
            if (headerRef && contentRef.current) {
                contentRef.current.style.marginTop = `${headerRef.clientHeight + 20}px`;
            }
        };
        
        // Adjust height on mount, window resize, and when dependencies change
        adjustHeight();
        window.addEventListener('resize', adjustHeight);

        // Re-run adjustHeight when dependencies change
        return () => {
            window.removeEventListener('resize', adjustHeight);
        };
    }, [headerRef, contentRef, deps]);
};

export default useAdjustHeight;