import React, { useState, useEffect, useContext, useCallback } from 'react';
import AuthContext from './AuthContext';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import './UpgradeSub.css';
import { CardElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import { StripeContext, StripeProvider } from './StripeContext'; 

const UpgradeSubComponent = () => {
  const navigate = useNavigate();
  const [tier, setTier] = useState('free');
  const [selectedTier, setSelectedTier] = useState('mid');
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const { token, isAuthenticated, logoutUser } = useContext(AuthContext);
  const [error, setError] = useState('');
  
  const tierDetails = {
    'mid': { label: 'Mid Tier', price: '$2 every week' },
    'high': { label: 'High Tier', price: '$5 every week' },
  };

  const handleLogout = () => {
    logoutUser();
    navigate('/login');
  };

  const fetchCurrentTier = useCallback(() => {
    const apiUrl = process.env.REACT_APP_API_URL
    fetch(`${apiUrl}/api/get-current-tier/`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Failed to fetch current tier');
        }
        return response.json();
    })
    .then(data => {
      if (data.currentTier === 'mid') {
        setTier('Mid')
      } else if (data.currentTier === 'high') {
        setTier('High')
      } else {
        setTier('Free')
      }
      
    })
    .catch(error => {
        console.error('Error:', error);
    });
  }, [token, setTier]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchCurrentTier();
    } else {
      navigate('/login');
    }
  }, [isAuthenticated, navigate, token, fetchCurrentTier]); 

  const handleCancelSubscription = async () => {
      setIsLoading(true);
      try {
          const apiUrl = process.env.REACT_APP_API_URL
          const response = await fetch(`${apiUrl}/api/cancel-subscription/`, {
              method: 'POST',
              headers: {
                  'Authorization': `Bearer ${token}`,
              },
          });

          if (!response.ok) {
              const errorData = await response.json();
              throw new Error(errorData.error || 'An error occurred');
          }

          fetchCurrentTier();
      } catch (error) {
          console.error('Failed to cancel subscription:', error);
          setError(error.message);
      } finally {
          setIsLoading(false);
      }
  };

  const handleTierSelection = (tier) => {
    setSelectedTier(tier);
  };

  const handleSubmitPayment = async () => {
    if (!stripe || !elements) {
        return;
    }

    setIsLoading(true);  // Set loading to true

    const cardElement = elements.getElement(CardElement);

    try {
        const paymentMethodResponse = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (paymentMethodResponse.error) {
            console.error('Error creating payment method:', paymentMethodResponse.error.message);
            setError(paymentMethodResponse.error.message);
            setIsLoading(false);
            return;
        }

        const paymentMethodId = paymentMethodResponse.paymentMethod.id;
        const apiUrl = process.env.REACT_APP_API_URL
        // Send the payment method ID along with the tier to your backend
        const response = await fetch(`${apiUrl}/api/create-subscription/`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ tier: selectedTier, payment_method_id: paymentMethodId }),
        });

        // Handle the response from the backend
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'An error occurred');
        }

        fetchCurrentTier();
        setIsLoading(false);
    } catch (error) {
        console.error('Failed to create subscription:', error);
        setError(error.message);
    } finally {
        setIsLoading(false);  // Reset loading state
    }
};

  return (
    <div className="upgrade-page">
      <Header title="Upgrade Subscription" onLogout={handleLogout} />
      <div className="upgrade-container">
        <h1>Upgrade Your Subscription</h1>
        <p>Current Tier: {tier}</p>

        <div className="tier-selection">
          {Object.keys(tierDetails).map((key) => (
            <button key={key} onClick={() => handleTierSelection(key)} className={selectedTier === key ? 'selected' : ''}>
              {tierDetails[key].label}
            </button>
          ))}
        </div>

        <p className="price-detail">You will be charged {tierDetails[selectedTier].price}</p>

        <CardElement />

        <button onClick={handleSubmitPayment} className="submit-payment" disabled={isLoading} >Submit Payment</button>
        {error && <div className="error-message">{error}</div>}
        <button onClick={handleCancelSubscription} className="cancel-subscription" disabled={isLoading}>Cancel Subscription</button>
      </div>
    </div>
  );
};

const UpgradeSub = () => {
    return (
      <StripeProvider>
        <ConnectedUpgradeSubComponent />
      </StripeProvider>
    );
  };
  
  const ConnectedUpgradeSubComponent = () => {
    const stripePromise = useContext(StripeContext);
  
    return (
      <Elements stripe={stripePromise}>
        <UpgradeSubComponent />
      </Elements>
    );
  };
  
  export default UpgradeSub;
