import React, { createContext, useState, useEffect, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [authData, setAuthData] = useState({
        token: localStorage.getItem('token'),
        refreshToken: localStorage.getItem('refreshToken'),
        isAuthenticated: !!localStorage.getItem('token'),
    });

    const refreshAccessToken = useCallback(async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/api/token/refresh/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ refresh: authData.refreshToken }),
            });

            if (!response.ok) {
                throw new Error('Failed to refresh token.');
            }

            const data = await response.json();
            localStorage.setItem('token', data.access);
            setAuthData(prevAuthData => ({
                ...prevAuthData,
                token: data.access,
            }));
        } catch (error) {
            console.error('Token refresh error:', error);
            logoutUser();
        }
    }, [authData.refreshToken]); // Add the dependency here

    useEffect(() => {
        const scheduleTokenRefresh = () => {
            const token = localStorage.getItem('token');
            if (!token) return;

            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            const delay = (decodedToken.exp - currentTime - 60) * 1000; // 60 seconds before expiry

            setTimeout(() => {
                refreshAccessToken();
            }, delay);
        };

        scheduleTokenRefresh();
    }, [refreshAccessToken]);

    const loginUser = async (username, password) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/api/token/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            if (!response.ok) {
                throw new Error('Failed to authenticate.');
            }
            const data = await response.json();
            localStorage.setItem('token', data.access); // Persist access token in local storage
            localStorage.setItem('refreshToken', data.refresh); // Persist refresh token in local storage
            setAuthData({
                token: data.access,
                refreshToken: data.refresh,
                isAuthenticated: true,
            });
            const login_response = await fetch(`${apiUrl}/api/login/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${data.access}`,
                }
            });
            if (!login_response.ok) {
                throw new Error('Failed to authenticate.');
            }
        } catch (error) {
            console.error('Authentication error:', error);
            // Add any additional error handling here
        }
    };

    const logoutUser = () => {
        localStorage.removeItem('token'); // Remove the access token from local storage on logout
        localStorage.removeItem('refreshToken'); // Remove the refresh token from local storage on logout
        setAuthData({
            token: null,
            refreshToken: null,
            isAuthenticated: false,
        });
    };

    // You can also add an effect to periodically refresh the token before it expires

    return (
        <AuthContext.Provider value={{ ...authData, loginUser, logoutUser, refreshAccessToken }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
