// ResetPasswordForm.jsx
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

function ResetPasswordForm() {
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;
    const { uid, token } = useParams(); // Extract both uid and token from the URL
    console.log(uid, token)
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            alert('Passwords do not match!');
            return;
        }
        try {
            await axios.post(`${apiUrl}/api/password_reset/confirm/`, {
                uid,  // Include uid in your request
                token,
                password
            });
            alert('Password successfully reset!');
            navigate('/login');
        } catch (error) {
            alert('Error resetting password');
        }
    };

    return (
        <div className="login-container">
            <form onSubmit={handleSubmit}>
                <h2 className="login-title">New Password</h2>
                <input
                    className="login-input"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="New Password"
                    required
                />
                <input
                    className="login-input"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm New Password"
                    required
                />
                <div className="form-footer">
                    <button type="submit" className="login-button">Reset Password</button>
                </div>
            </form>
        </div>
    );
}

export default ResetPasswordForm;