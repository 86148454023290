import './App.css';
import RouteProtector from './RouteProtector';
import { AuthProvider } from './AuthContext'; 
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import JobHunterProfile from './JobHunterProfile';
import TailoredResume from './TailoredResume';
import TailoredSkills from './TailoredSkills';
import TailoredInterview from './TailoredInterview';
import UpgradeSub from './UpgradeSub';
import ForgotPasswordForm from './ForgotPasswordForm'; // Import ForgotPasswordForm
import ResetPasswordForm from './ResetPasswordForm'; // Import ResetPasswordForm
import LandingPage from './LandingPage';
import Activation from './Activation';
import FeedbackForm from './FeedbackForm';
import React from 'react';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/register" element={<RegisterForm />} />
            <Route path="/activate/:uid/:token" element={<Activation />} />
            <Route path="/forgot_password" element={<ForgotPasswordForm />} />
            <Route path="/reset_password/:uid/:token" element={<ResetPasswordForm />}/>
            <Route 
                path="/jh_profile" 
                element={
                    <RouteProtector>
                        <JobHunterProfile />
                    </RouteProtector>
                } 
            />
            <Route 
                path="/tailor_resume" 
                element={
                    <RouteProtector>
                        <TailoredResume />
                    </RouteProtector>
                } 
            />
            <Route
                path="/tailor_skills"
                element={
                    <RouteProtector>
                        <TailoredSkills />
                    </RouteProtector>
                }
            />
            <Route
                path="/tailor_interview"
                element={
                    <RouteProtector>
                        <TailoredInterview />
                    </RouteProtector>
                }
            />
            <Route
                path="/upgrade_subscription"
                element={
                    <RouteProtector>
                        <UpgradeSub />
                    </RouteProtector>
                }
            />
            <Route 
                path="/user_feedback" 
                element={
                    <RouteProtector>
                        <FeedbackForm />
                    </RouteProtector>
                } 
            />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
