import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const Activation = () => {
    const navigate = useNavigate();
    const { uid, token } = useParams();
    const [message, setMessage] = useState('Activating your account...');
    const apiUrl = process.env.REACT_APP_API_URL
    useEffect(() => {
        const activateAccount = async () => {
            
            if (!uid || !token) {
                setMessage('Invalid activation link.');
                return;
            }

            try {
                const response = await fetch(`${apiUrl}/api/activate/?uid=${uid}&token=${token}`, {
                    method: 'GET',
                });

                if (response.ok) {
                    alert('Successfully activated your account.');
                    navigate('/login');
                } else {
                    alert('An error occurred. Please try again.');
                }
            } catch (error) {
                alert('An error occurred. Please try again.');
            }
        };

        activateAccount();
    }, [navigate, apiUrl, uid, token]);

    return (
        <div>
            <h2>Account Activation</h2>
            <p>{message}</p>
        </div>
    );
};

export default Activation;
