import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './TailoredInterview.css';
import AuthContext from './AuthContext';
import Header from './Header';

const TailoredInterview = () => {
    const navigate = useNavigate();
    const [text, setText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [interviewQuestions, setInterviewQuestions] = useState([]);
    const [analysisResults, setAnalysisResults] = useState(new Map());
    const { token, isAuthenticated, logoutUser, refreshAccessToken } = useContext(AuthContext);
    const [quotaError, setQuotaError] = useState(false);
    const mainContentRef = useRef(null);
    const apiUrl = process.env.REACT_APP_API_URL

    const handleLogout = () => {
        logoutUser();
        navigate('/login');
    };

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        }
    }, [isAuthenticated, navigate]);

    const handleTextSubmit = (event) => {
        setIsLoading(true);
        event.preventDefault();
        const data = { job_details: text };
    
        const submitData = (retry = false) => {
            fetch(`${apiUrl}/api/generate-tailored-interview/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then(response => {
                if (response.status === 401 && !retry) {
                    // Try to refresh token and retry submitting data once
                    return refreshAccessToken().then(() => submitData(true));
                }
                if (response.status === 403) {
                    throw new Error("Usage quota exceeded");
                }
                return response.json();
            })
            .then(data => {
                setInterviewQuestions(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error:', error);
                if (error.message === "Usage quota exceeded") {
                    setQuotaError(true);
                }
                setIsLoading(false);
            });
        };
    
        submitData();
    };
    

    const [recording, setRecording] = useState(false);

    const startRecording = (questionIndex) => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                const options = { mimeType: 'audio/webm' };
                const mediaRecorder = new MediaRecorder(stream, options);
                const chunks = [];
                mediaRecorder.start();
    
                mediaRecorder.addEventListener("dataavailable", event => {
                    chunks.push(event.data);
                });
    
                setRecording({ status: true, recorder: mediaRecorder, questionIndex, chunks });
            })
            .catch(error => {
                console.error("Error accessing media devices:", error);
            });
    };

    const stopRecording = (questionIndex) => {
        setIsLoading(true);
        recording.recorder.stop();
        recording.recorder.addEventListener("stop", () => {
            const audioBlob = new Blob(recording.chunks, { type: 'audio/webm' });
            const question = interviewQuestions[questionIndex].question;
            setIsLoading(true);
            uploadAudio(audioBlob, question, questionIndex);
        });
        setRecording(false);
    };

    const uploadAudio = (audioBlob, question, questionIndex) => {
        const formData = new FormData();
        formData.append('audio', audioBlob, `response_${questionIndex}.webm`);
        formData.append('question', question);
        console.log("Audio blob size:", audioBlob.size);
    
        const upload = (retry = false) => {
            fetch(`${apiUrl}/api/analyze-audio/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            })
            .then(response => {
                if (response.status === 401 && !retry) {
                    // Try to refresh token and retry submitting data once
                    return refreshAccessToken().then(() => upload(true));
                }
                if (!response.ok) {
                    throw new Error('Failed to upload audio');
                }
                return response.json();
            })
            .then(data => {
                setIsLoading(false);
                setAnalysisResults(prev => new Map(prev).set(questionIndex, data));
            })
            .catch(error => {
                console.error('Error:', error);
                setIsLoading(false);
            });
        };
    
        upload();
    };
    

    return (
        <div className="interview-page">
            <Header title="Tailored Interview Prep" onLogout={handleLogout} />
            <main className="interview-form-main" ref={mainContentRef}>
                <div className="f-modal-base-large">
                    <div className="f-modal-title-wrapper">
                        <div className="f-sub-heading-regular">Prep for a Job Interview</div>
                    </div>
                    <div className="f-modal-line"></div>
                        <div className="w-form">
                            <form id="email-form" name="email-form" data-name="Email Form" method="get" data-wf-page-id="65d123afabbdd97a28b30374" data-wf-element-id="20931e75-87b3-acde-2f50-563023bdd402">
                            <div className="w-layout-grid f-account-input-grid">
                                <div className="f-field-wrapper-2">
                                    <div data-hover="false" data-delay="0" className="f-dropdown w-dropdown">
                                    </div>
                                </div>
                                <div className="f-field-wrapper-2">
                                    <div className="f-field-label">Job Description</div>
                                    <textarea 
                                        value={text}
                                        onChange={(e) => setText(e.target.value)}
                                        placeholder="Copy and paste job description, details and requirements here for the AI to use when updating your resume. " maxLength="5000" id="Modal-Field-Large" name="Modal-Field-Large" data-name="Modal Field Large" className="f-text-area-2 w-input"></textarea>
                                </div>
                            </div>
                            </form>
                        </div>
                    <div className="f-modal-line"></div>
                    <button onClick={handleTextSubmit} className="urlSubmit" type="submit" disabled={isLoading}>
                        {isLoading ? 'Analyzing...' : 'Analyze Job Description'}
                    </button>
                    {isLoading}
                </div>
                {
                    quotaError && (
                        <div className="quota-error-message">
                            You have exceeded your usage quota for this week.
                        </div>
                    )
                }
                <div className="interview-questions">
                    {interviewQuestions.map((item, index) => (
                        <div key={index} className="question-block">
                            <audio controls>
                                <source src={item.audio_url} type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>
                            <div>
                                {recording.status && recording.questionIndex === index ? (
                                    <button className={`stopButton ${isLoading ? 'loading' : ''}`} disabled={isLoading} onClick={() => stopRecording(index)}>
                                        {isLoading ? 'Stopping...' : 'Stop Recording'}
                                    </button>
                                ) : (
                                    <button className={`startButton ${isLoading ? 'loading' : ''}`} disabled={isLoading} onClick={() => startRecording(index)}>
                                        {isLoading ? 'Starting...' : 'Start Recording'}
                                    </button>
                                )}
                            </div>
                            {analysisResults.get(index) && (
                                <div className="analysis-result">
                                    <p className="analysis-header">Analysis</p>
                                    <p>{analysisResults.get(index)}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </main>
        </div>
    );
};

export default TailoredInterview;
