import React, { createContext, useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';

export const StripeContext = createContext();

export const StripeProvider = ({ children }) => {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    async function fetchStripeKey() {
      try {
        const apiUrl = process.env.REACT_APP_API_URL
        const response = await fetch(`${apiUrl}/api/get-stripe-key/`);
        const data = await response.json();
        if (data.publishableKey) {
          const stripe = loadStripe(data.publishableKey);
          setStripePromise(stripe);
        }
      } catch (error) {
        console.error('Error fetching Stripe key:', error);
      }
    }

    fetchStripeKey();
  }, []);

  return (
    <StripeContext.Provider value={stripePromise}>
      {children}
    </StripeContext.Provider>
  );
};
