import React, { useState, useEffect, useContext, useRef } from 'react';
import AuthContext from './AuthContext';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import './TailoredResume.css';

const TailoredResume = () => {
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState('');
    const [text, setText] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [tailoredResume, setTailoredResume] = useState(null);
    const [originalResume, setOriginalResume] = useState(null);
    const [resumeFileUrl, setResumeFileUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { token, isAuthenticated, logoutUser, refreshAccessToken } = useContext(AuthContext);
    const [quotaError, setQuotaError] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL
    const handleLogout = () => {
        logoutUser();
        navigate('/login');
    };

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        }
    }, [isAuthenticated, navigate]);

    const processResumeText = (text) => {
        return text.split('\n')
            .map((line, index) => {
                // Check for bullet points and replace with HTML list items
                let processedLine = line.trim();
                if (processedLine.startsWith('*') || processedLine.startsWith('-') || processedLine.startsWith('•')) {
                    processedLine = `<li>${processedLine.substring(1).trim()}</li>`;
                } else {
                    processedLine = processedLine.length > 0 ? `<span>${processedLine}<br/></span>` : null;
                }
                return processedLine ? <div key={index} dangerouslySetInnerHTML={{ __html: processedLine }} /> : null;
            })
            .filter(line => line !== null); // Filter out empty lines or null elements
    };    

    const mainContentRef = useRef(null); // Ref for the main content

    useEffect(() => {
        const apiUrl = process.env.REACT_APP_API_URL
        const fetchWithTokenRefresh = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/get-uploaded-files/`, {
                    headers: new Headers({
                        'Authorization': `Bearer ${token}`,
                    }),
                });

                if (response.status === 401) {
                    // If the token is invalid or expired, try to refresh it
                    return refreshAccessToken()
                }

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                if (Array.isArray(data)) {
                    setUploadedFiles(data);
                } else {
                    console.error('Expected an array of files, got:', data);
                }
            } catch (error) {
                console.error('Error fetching files:', error);
            }
        };

        fetchWithTokenRefresh();
    }, [token, navigate, refreshAccessToken]);

    const handleSubmit = (retry = false) => {
        setIsLoading(true);
        const data = { selectedFile, text };
        fetch(`${apiUrl}/api/generate-tailored-resume/`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(response => {
            if (response.status === 401 && !retry) {
                // Try to refresh token and retry submitting data once
                return refreshAccessToken().then(() => handleSubmit(true));
            }
            if (response.status === 403) {
                throw new Error("Usage quota exceeded");
            }
            return response.json();
        })
        .then(data => {
            setTailoredResume(data.tailoredResume);
            setOriginalResume(data.originalResume);
            setResumeFileUrl(data.fileUrl);
            setIsLoading(false);
        })
        .catch(error => {
            console.error('Error generating tailored resume:', error);
            if (error.message === "Usage quota exceeded") {
                // Set a state to display quota error message
                setQuotaError(true);
            }
            setIsLoading(false); // Stop loading
        });
    };

    return (
        <div className="tailored-resume-container">
            <Header 
                title="Tailored Resume Builder" 
                onLogout={handleLogout}
            />
            <main className="tailored-resume-main" ref={mainContentRef}>
                <div className="f-modal-base-large">
                    <div className="f-modal-title-wrapper">
                        <div className="f-sub-heading-regular">Tailored Resume Setup</div>
                    </div>
                    <div className="f-modal-line"></div>
                    <div className="w-form">
                        <form id="email-form" name="email-form" data-name="Email Form" method="get" data-wf-page-id="65d123afabbdd97a28b30374" data-wf-element-id="20931e75-87b3-acde-2f50-563023bdd402">
                        <div className="w-layout-grid f-account-input-grid">
                            <div className="f-field-wrapper-2">
                            <div data-hover="false" data-delay="0" className="f-dropdown w-dropdown">
                                <label htmlFor="f-paragraph-small">Select Your File:</label>
                                    <select
                                        id="file-select"
                                        value={selectedFile}
                                        onChange={e => setSelectedFile(e.target.value)}
                                    >
                                        <option value="">Select a file</option>
                                        {uploadedFiles.map(file => (
                                            <option key={file.id} value={file.id}>{file.name}</option>
                                        ))}
                                    </select>
                            </div>
                            </div>
                            <div className="f-field-wrapper-2">
                            <div className="f-field-label">Job Description</div>
                                <textarea 
                                    value={text}
                                    onChange={(e) => setText(e.target.value)}
                                    placeholder="Copy and paste job description, details and requirements here for the AI to use when updating your resume. " maxLength="5000" id="Modal-Field-Large" name="Modal-Field-Large" data-name="Modal Field Large" className="f-text-area-2 w-input"></textarea>
                            </div>
                        </div>
                        </form>
                    </div>
                    <div className="f-modal-line"></div>
                    <button onClick={handleSubmit} disabled={isLoading}>
                            {isLoading ? 'Generating...' : 'Generate Tailored Resume'}
                    </button>
                    {isLoading}
                    </div>
                {
                    quotaError && (
                        <div className="quota-error-message">
                            You have exceeded your usage quota for this week.
                        </div>
                    )
                }
                {(originalResume || tailoredResume) && (
                    <div className="resumes-container">
                        {originalResume && (
                            <div className="original-resume">
                                <h3>Original Resume:</h3>
                                <div>{processResumeText(originalResume)}</div>
                            </div>
                        )}
                        {tailoredResume && (
                            
                            <div className="tailored-resume">
                                {resumeFileUrl && (
                                    <a href={resumeFileUrl} download className="download-button">
                                        Download Tailored Resume
                                    </a>
                                )}
                                <h3>Tailored Resume:</h3>
                                <div>{processResumeText(tailoredResume)}</div>
                            </div>
                        )}
                    </div>
                    )}
            </main>
        </div>
    );
};

export default TailoredResume;
