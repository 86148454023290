import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AuthContext from './AuthContext'; // Adjust the path based on your directory structure
import './LoginForm.css'; // Ensure this points to your CSS file
import logo from './logos/logo.png';

const LoginForm = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { loginUser } = useContext(AuthContext); // Destructure loginUser from context

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(''); // Clear previous errors

        try {
            // Use the loginUser function from context
            await loginUser(username, password);
            // Redirect the user to the profile page on successful login
            navigate('/jh_profile');
        } catch (error) {
            // If loginUser throws, handle errors here
            setError('Login failed. Please try again.');
        }
    };

    return (
        <div>
            <img src={logo} alt="Bridge Path - Job Hunting AI" className="login-form-logo" />
            <div className="login-container">
                <form onSubmit={handleSubmit} className="login-form">
                    <h2 className="login-title">LOG IN</h2>
                    <div className="input-container">
                        <input
                            type="text"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                            className="login-input"
                        />
                    </div>
                    <div className="input-container">
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="login-input"
                        />
                    </div>
                    {error && <p className="login-error">{error}</p>}
                    <div className="form-footer">
                        <button type="submit" className="login-button">Login</button>
                        <a href="/forgot_password" className="forgot-password">Forgot password?</a>
                        <Link to="/register" className="register-link">Register</Link>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LoginForm;
