// Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from './logos/header_logo.png';

const Header = ({ title, onLogout }) => {
    const navItems = [
        { name: "My Profile", path:"/jh_profile"},
        { name: "Tailored Resume Builder", path: "/tailor_resume" },
        { name: "Tailored Interview Prep", path: "/tailor_interview" },
        { name: "Tailored Skills Developer", path: "/tailor_skills" },
        { name: "Upgrade Subscription", path: "/upgrade_subscription" },
        { name: "Provide Feedback", path: "/user_feedback"}
    ];

    return (
        <header className="profile-header">
            <img src={logo} alt="Logo" className="logo" />
            <nav className="profile-nav">
                <ul>
                    {navItems.map((item, index) => (
                        <li key={index}>
                            <Link to={item.path}>{item.name}</Link>
                        </li>
                    ))}
                </ul>
                <button onClick={onLogout} className="logout-button">Logout</button>
            </nav>
        </header>
    );
};

export default Header;
