import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from './AuthContext';
import './FeedbackForm.css';
import Header from './Header';

const FeedbackForm = () => {
    const navigate = useNavigate();
    const [feedback, setFeedback] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState('');
    const { token, logoutUser } = useContext(AuthContext);

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleLogout = () => {
        logoutUser();
        navigate('/login');
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        setSubmitSuccess(false);
        setSubmitError('');

        fetch(`${apiUrl}/api/user_feedback/`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ feedback }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to submit feedback');
            }
            return response.json();
        })
        .then(data => {
            setSubmitSuccess(true);
            setFeedback('');
        })
        .catch(error => {
            setSubmitError(error.message);
        })
        .finally(() => {
            setIsSubmitting(false);
        });
    };

    return (
        <div className="interview-page">
            <Header title="Tailored Interview Prep" onLogout={handleLogout} />
            <div className="feedback-form">
                <main className="interview-form-main">
                    <h2>Submit Your Feedback</h2>
                    <form onSubmit={handleSubmit}>
                        <textarea
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                            placeholder="Enter your feedback here"
                            required
                        ></textarea>
                        <button type="submit" disabled={isSubmitting}>
                            {isSubmitting ? 'Submitting...' : 'Submit Feedback'}
                        </button>
                    </form>
                    {submitSuccess && <p>Feedback submitted successfully!</p>}
                    {submitError && <p>Error submitting feedback: {submitError}</p>}
                </main>
            </div>
        </div>
    );
};

export default FeedbackForm;
