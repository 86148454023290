import React, { useState, useEffect, useContext, useRef } from 'react';
import AuthContext from './AuthContext';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import './JobHunterProfile.css';
import useAdjustHeight from './LayoutUtils';
import ToolTip from './ToolTip';

const JobHunterProfile = () => {
    const navigate = useNavigate();
    const { token, isAuthenticated, logoutUser, refreshAccessToken } = useContext(AuthContext);
    const [profileData, setProfileData] = useState({
        fullName: '',
        description: '',
    });
    const [loading, setLoading] = useState(true);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [documentSummaries, setDocumentSummaries] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL
    const handleLogout = () => {
        logoutUser();
        navigate('/login');
    };

    const mainContentRef = useRef(null); // Ref for the main content
    const headerRef = useRef(null); // Ref for the header

    useAdjustHeight(headerRef, mainContentRef);
    
    const handleFileUpload = async () => {
        if (!selectedFile) {
            alert('Please select a file first!');
            return;
        }

        setIsUploading(true);

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const response = await fetch(`${apiUrl}/api/jobhunter/upload/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('File upload failed');
            }

            setIsUploading(false);
            window.location.reload();
    
        } catch (error) {
            console.error('Upload error:', error);
            setIsUploading(false);
        }
    };

    useEffect(() => {
        const apiUrl = process.env.REACT_APP_API_URL
        const fetchProfileData = async (retry = false) => {
            if (!token) {
                navigate('/login');
                return;
            }

            try {
                const response = await fetch(`${apiUrl}/api/jobhunter/profile/`, {
                    headers: new Headers({
                        'Authorization': `Bearer ${token}`,
                    }),
                });

                if (response.status === 401  && !retry) {
                    return refreshAccessToken().then(() => fetchProfileData(true));
                }

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setProfileData({
                    fullName: data.full_name,
                    description: data.profile_description,
                });
            } catch (error) {
                console.error('Failed to fetch profile data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchProfileData();
        const fetchDocumentSummaries = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/get_document_summaries/`, {
                    headers: new Headers({
                        'Authorization': `Bearer ${token}`,
                    }),
                });

                if (response.status === 401) {
                    await refreshAccessToken();
                    return;
                }

                if (!response.ok) {
                    throw new Error('Failed to fetch document summaries');
                }

                const data = await response.json();
                setDocumentSummaries(data);
            } catch (error) {
                console.error('Error fetching document summaries:', error);
            }
        };
    
        if (isAuthenticated) {
            fetchDocumentSummaries();
        }
    }, [token, navigate, isAuthenticated, refreshAccessToken]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated || !profileData) {
        return <div>User is not authenticated or profile data is not available.</div>;
    }

    return (
        <div className="profile-container">
            <Header 
                title={profileData.fullName} 
                onLogout={handleLogout} 
                ref={headerRef}
            />
            <main className="profile-main" ref={mainContentRef}>
                <div className="file-upload-section">
                    <ToolTip message="Please upload work-related files like resumes, code, cover letters, etc. that our tool can use when assessing them for a given job." />
                    <input
                        type="file"
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                    />
                    <button onClick={handleFileUpload} disabled={isUploading}>
                        {isUploading ? 'Uploading...' : 'Upload'}
                    </button>
                    {isUploading && <div className="loader"></div>}
                </div>
                <section className="documents-section">
                    {documentSummaries.map((document, index) => (
                        <div key={index} className="document-summary-box">
                            <h3>{document.filename}</h3>
                            <p>{document.summary}</p>
                        </div>
                    ))}
                </section>
            </main>
        </div>
    );
};

export default JobHunterProfile;
