// ForgotPasswordForm.jsx
import React, { useState } from 'react';
import axios from 'axios';

function ForgotPasswordForm() {
    const [email, setEmail] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await axios.post(`${apiUrl}/api/forgot_password/`, JSON.stringify({ email: email }), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            alert('Password reset link sent!');
        } catch (error) {
            if (error.response && error.response.data && error.response.data.email) {
                setErrorMessage(error.response.data.email[0]);
            } else {
                setErrorMessage('Error sending password reset link');
            }
        }
    };

    return (
        <div className="login-container">
            <form onSubmit={handleSubmit}>
                <h2 className="login-title">Reset Password</h2>
                <input
                    className="login-input"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                />
                <div className="form-footer">
                    <button type="submit" className="login-button">Send Reset Link</button>
                </div>
                {errorMessage && <p className="error-message">{errorMessage}</p>}

            </form>
        </div>
    );
}

export default ForgotPasswordForm;
