import React from 'react';
import intro from './logos/intro.png';
import './LandingPage.css';

const LandingPage = () => {
    return (
        <div className="interview-page">
          <div className="hero-heading-left-2">
            <div className="container-2">
            <div className="column-2">
                <div className="column-2">
                <h1 className="title-copy-goes-here-be-awesome">
                    <h1 className="title-copy-goes-here-be-awesome title-copy-goes-here-be-awesome"><span className="title-copy-goes-here-be-awesome-0">Unleash the power of </span><span className="title-copy-goes-here-be-awesome-1">AI</span><span className="title-copy-goes-here-be-awesome-0"> to Job Hunt Effectively</span></h1>
                </h1>
                <p className="lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-suspendisse-varius-enim-in-eros-elementum-tristique-duis-cursus-mi-quis-viverra-ornare-eros-dolor-interdum-nulla-ut-commodo-diam-libero-vitae-erat">We designed our AI to understand your unique qualification and the details of your interests in order to lead you towards achieving your career goals.</p>
                </div>
                <div className="actions">
                <a href="/login" className="button-2">
                    <div className="text-2">Login</div>
                </a>
                <a href="/register" className="button-2">
                    <div className="text-2">Register</div>
                </a>
                </div>
            </div>
            <div className="column-3">
                <div className="image-wrapper"><img src={intro} loading="lazy" alt="" className="f-image-cover"></img></div>
            </div>
            </div>
        </div>
        <div className="heading-3-columns">
            <div className="header f2wf-columns">
            <div className="heading---desktop">
                <div className="heading---desktop-2">The most powerful features</div>
                <div className="supporting-text---desktop">The first Unified Go-to-market Platform, Distrobird has all the tools you need to effortlessly run your sales organization</div>
            </div>
            </div>
            <div className="_3-columns">
            <div className="column-4">
                <div className="feature-cards---desktop">
                <div className="feature-contents---desktop">
                    <div className="users">
                        <img
                            className="book-open-text"
                            alt="Book open text"
                            src="https://cdn.animaapp.com/projects/65de5bb9767c9f3504cf76d5/releases/65de5f98ac07c697cb5789b7/img/book-open-text-1@2x.png"
                            />  
                    </div>
                    <div className="feature-contents---desktop">
                    <div className="text-3">Tailored Resume</div>
                    <div className="supporting-text---desktop">Provide your resume, examples of your work (i.e. code, blog posts), and a job description, and our AI will revise portions of your resume.</div>
                    </div>
                </div>
                </div>
            </div>
            <div className="column-4">
                <div className="feature-cards---desktop">
                <div className="feature-contents---desktop">
                    <img
                        className="users"
                        alt="Sparkle"
                        src="https://cdn.animaapp.com/projects/65de5bb9767c9f3504cf76d5/releases/65de5f98ac07c697cb5789b7/img/sparkle-1@2x.png"
                    />
                    <div className="feature-contents---desktop">
                    <div className="text-3">Skill Building</div>
                    <div className="supporting-text---desktop">Found a job that interests you? Share the details with our AI, and it will pinpoint areas for improving your skills and suggest specific tools to assist you.</div>
                    </div>
                </div>
                </div>
            </div>
            <div className="column-4">
                <div className="feature-cards---desktop-2">
                <div className="feature-contents---desktop">
                    <img
                          className="contactless-payment"
                          alt="Contactless payment"
                          src="https://cdn.animaapp.com/projects/65de5bb9767c9f3504cf76d5/releases/65de5f98ac07c697cb5789b7/img/contactless-payment-1@2x.png"
                        />
                    <div className="feature-contents---desktop">
                    <div className="text-3">Interview Prep</div>
                    <div className="supporting-text---desktop">Preparing for an upcoming interview? Share the job requirements with our AI, let it conduct the interview, and receive feedback on your audio responses.</div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        <section className="hero-heading-right">
            <div className="header-2 f2wf-columns">
            <div className="pricing-heading---desktop">
                <div className="pricing-heading-text---desktop">Pricing Focused on Getting you a Job</div>
                <div className="pricing-supporting-text---desktop">Simple pricing. No hidden fees. Cancel Anytime.</div>
            </div>
            </div>
            <div className="_3-columns-2">
            <div className="pricing-card---desktop">
                <div className="heading-price-content---desktop">
                <div className="heading-supporting-text">
                    <div className="heading-2">Free</div>
                    <div className="supporting-text-2">Available for all, basic usage to help get an interview</div>
                </div>
                <div className="price-supporting-text">
                    <div className="price">$0</div>
                    <div className="supporting-text-3">
                    <div className="supporting-text-4">/per week</div>
                    </div>
                </div>
                </div>
                <div className="pricing-feature-items-action---desktop">
                <div className="check-items">
                    <div className="check-item">
                        <img
                            className="check-icon"
                            alt="Check icon"
                            src="https://cdn.animaapp.com/projects/65de5bb9767c9f3504cf76d5/releases/65de5f98ac07c697cb5789b7/img/check-icon-4@2x.png"
                        />
                    <div className="text-4">1 Tailored Resumes</div>
                    </div>
                    <div className="check-item">
                        <img
                            className="check-icon"
                            alt="Check icon"
                            src="https://cdn.animaapp.com/projects/65de5bb9767c9f3504cf76d5/releases/65de5f98ac07c697cb5789b7/img/check-icon-4@2x.png"
                        />
                    <div className="text-4">Unlimited Tailored Skills Building</div>
                    </div>
                    <div className="check-item"></div>
                </div>
                <a href="/register" className="button-3">
                    <div className="text-5">Get Started</div>
                </a>
                </div>
            </div>
            <div className="pricing-card---desktop">
                <div className="heading-price-content---desktop">
                <div className="heading-supporting-text">
                    <div className="heading-2">Mid-Tier</div>
                    <div className="supporting-text-2">For casual hunters to help you gain skills, apply and interview.</div>
                </div>
                <div className="price-supporting-text">
                    <div className="price">$2</div>
                    <div className="supporting-text-3">
                    <div className="supporting-text-4">/per week</div>
                    </div>
                </div>
                <div className="badges-rounded">
                    <img
                        className="crown-simple"
                        alt="Crown simple"
                        src="https://cdn.animaapp.com/projects/65de5bb9767c9f3504cf76d5/releases/65de5f98ac07c697cb5789b7/img/crown-simple-1@2x.png"
                        />
                    <div className="badge-2">Popular</div>
                </div>
                </div>
                <div className="pricing-feature-items-action---desktop">
                <div className="check-items">
                    <div className="check-item">
                        <img
                            className="check-icon"
                            alt="Check icon"
                            src="https://cdn.animaapp.com/projects/65de5bb9767c9f3504cf76d5/releases/65de5f98ac07c697cb5789b7/img/check-icon-4@2x.png"
                        />
                    <div className="text-4">3 Tailored Resumes</div>
                    </div>
                    <div className="check-item">
                        <img
                            className="check-icon"
                            alt="Check icon"
                            src="https://cdn.animaapp.com/projects/65de5bb9767c9f3504cf76d5/releases/65de5f98ac07c697cb5789b7/img/check-icon-4@2x.png"
                        />
                    <div className="text-4">2 Interviews</div>
                    </div>
                    <div className="check-item">
                        <img
                            className="check-icon"
                            alt="Check icon"
                            src="https://cdn.animaapp.com/projects/65de5bb9767c9f3504cf76d5/releases/65de5f98ac07c697cb5789b7/img/check-icon-4@2x.png"
                        />
                    <div className="text-4">Unlimited Tailored Skills Building</div>
                    </div>
                </div>
                <a href="/register" className="button-3">
                    <div className="text-5">Get Started</div>
                </a>
                </div>
            </div>
            <div className="pricing-card---desktop">
                <div className="heading-price-content---desktop">
                <div className="heading-supporting-text">
                    <div className="heading-2">High Tier</div>
                    <div className="supporting-text-2">For active hunters to help you gain skills, apply, interview and get that next job.</div>
                </div>
                <div className="price-supporting-text">
                    <div className="price">$5</div>
                    <div className="supporting-text-3">
                    <div className="supporting-text-4">/per week</div>
                    </div>
                </div>
                </div>
                <div className="pricing-feature-items-action---desktop">
                <div className="check-items">
                    <div className="check-item"><img
                              className="check-icon"
                              alt="Check icon"
                              src="https://cdn.animaapp.com/projects/65de5bb9767c9f3504cf76d5/releases/65de5f98ac07c697cb5789b7/img/check-icon-4@2x.png"
                            />
                    <div className="text-4">10 Tailored Resumes</div>
                    </div>
                    <div className="check-item"><img
                              className="check-icon"
                              alt="Check icon"
                              src="https://cdn.animaapp.com/projects/65de5bb9767c9f3504cf76d5/releases/65de5f98ac07c697cb5789b7/img/check-icon-4@2x.png"
                            />
                    <div className="text-4">5 Interviews</div>
                    </div>
                    <div className="check-item"><img
                              className="check-icon"
                              alt="Check icon"
                              src="https://cdn.animaapp.com/projects/65de5bb9767c9f3504cf76d5/releases/65de5f98ac07c697cb5789b7/img/check-icon-4@2x.png"
                            />
                    <div className="text-4">Unlimited Tailored Skills Building</div>
                    </div>
                </div>
                <a href="/register" className="button-3">
                    <div className="text-5">Get Started</div>
                </a>
                </div>
            </div>
            </div>
        </section>
        <div className="heading-3-columns">
            <div className="header f2wf-columns">
            <div className="heading---desktop">
                <div className="heading---desktop-2">Frequently asked questions</div>
            </div>
            </div>
            <div className="_3-columns">
            <div className="column-4">
                <div className="faq-cards---desktop">
                <div className="feature-contents---desktop">
                    <div className="text-6">How do I start?</div>
                    <div className="supporting-text---desktop">Register for an account, upload at least a resume in your profile page then leverage the AI features: Resume Tailoring, Interview Prep, and Skill Building. I recommend finding a job, using the Skill Building feature before resume tailoring. The skill building feature will help you gain skills missing in your resume that are required by the job, so it’ll help you gain those skills so you can add them to your resume. Then tailor your resume with Tailored Resume and use that new resume to apply!</div>
                </div>
                </div>
            </div>
            <div className="column-4">
                <div className="faq-cards---desktop">
                <div className="feature-contents---desktop">
                    <div className="text-6">How is my data used?</div>
                    <div className="supporting-text---desktop">In your profile page you can upload documents like resume, code, writing, etc and the AI will use those documents to learn about your experience and skills so it can compare them to job posting you provide in the resume tailoring, interview prep, and skill building. The more you provide the more the AI will learn about you to more accurately tailor its output. </div>
                </div>
                </div>
            </div>
            <div className="column-4">
                <div className="faq-cards---desktop-2">
                <div className="feature-contents---desktop">
                    <div className="text-6">Why are there different tiers?</div>
                    <div className="supporting-text---desktop">The pricing structure is not fixed, I will be assessing and reducing pricing (if possible) once I get a better understanding of how much the hosting costs. My goal is to always provide a free tier and cheap extended usage tiers, not take advantage of people job hunting.</div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
      );
    };

export default LandingPage;
